import {
  SERIALNUMBERS_SELECT_ALL,
  SERIALNUMBERS_UNSELECT_ALL,
  SERIALNUMBER_SELECTED,
  SERIALNUMBERS_LOAD,
  SERIALNUMBERS_LOAD_SUCCESS,
  SERIALNUMBERS_LOAD_FAILED,
  SERIALNUMBER_LOAD,
  SERIALNUMBER_LOAD_SUCCESS,
  SERIALNUMBER_LOAD_FAILED,
  SERIALNUMBER_ADD,
  SERIALNUMBER_ADD_SUCCESS,
  SERIALNUMBER_ADD_FAILED,
  SERIALNUMBER_UPDATE,
  SERIALNUMBER_UPDATE_SUCCESS,
  SERIALNUMBER_UPDATE_FAILED,
  SERIALNUMBER_DELETE,
  SERIALNUMBER_DELETE_SUCCESS,
  SERIALNUMBER_DELETE_FAILED,
  SERIALNUMBER_DELETE_ALL,
  SERIALNUMBER_DELETE_ALL_SUCCESS,
  SERIALNUMBER_DELETE_ALL_FAILED,
  SERIALNUMBERS_EXPORT_EXCEL,
  SERIALNUMBERS_EXPORT_EXCEL_SUCCESS,
  SERIALNUMBERS_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';
import { PAGE_SIZES } from '../constants/AppConstant';

const INIT_STATE = {
  paging: {
    records: [],
    offset: 0,
    limit: PAGE_SIZES,
    totalRecords: 0
  },
  selectedSerialNumber: null,
  selectedSerialNumberId: null,
  message: null,
  messageType: 'info',
  filter: {
    offset: 0,
    limit: PAGE_SIZES,
    orderBy: 'codeSorted desc',
    filterGroups: null
  },
  excelBlob: null,
  hideLoading: true
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SERIALNUMBERS_SELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = true;
            return data;
          })
        }
      }
    case SERIALNUMBERS_UNSELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = false;
            return data;
          })
        }
      }
    case SERIALNUMBER_SELECTED:
      return {
        ...state,
        selectedSerialNumber: action.payload,
        selectedSerialNumberId: action.payload.id
      };
    case SERIALNUMBERS_LOAD:
      return {
        ...state,
        filter: action.payload ? action.payload : state.filter
      };
    case SERIALNUMBERS_LOAD_SUCCESS:
      return {
        ...state,
        paging: action.payload
      };
    case SERIALNUMBERS_LOAD_FAILED:
      return {
        ...state
      };
      case SERIALNUMBERS_EXPORT_EXCEL:
        return {
          ...state,
          excelBlob: null
        };
      case SERIALNUMBERS_EXPORT_EXCEL_SUCCESS:
        return {
          ...state,
          excelBlob: action.payload
        };
      case SERIALNUMBERS_EXPORT_EXCEL_FAILED:
        return {
          ...state,
          excelBlob: null
        };
    case SERIALNUMBER_LOAD:
      return {
        ...state
      };
    case SERIALNUMBER_LOAD_SUCCESS:
      return {
        ...state
      };
    case SERIALNUMBER_LOAD_FAILED:
      return {
        ...state
      };
    case SERIALNUMBER_ADD:
      return {
        ...state,
        hideLoading: false
      };
    case SERIALNUMBER_ADD_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case SERIALNUMBER_ADD_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case SERIALNUMBER_UPDATE:
      return {
        ...state,
        hideLoading: false
      };
    case SERIALNUMBER_UPDATE_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case SERIALNUMBER_UPDATE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case SERIALNUMBER_DELETE:
      return {
        ...state,
        hideLoading: false
      };
    case SERIALNUMBER_DELETE_SUCCESS:
      return {
        ...state,
        message: action.message,
        hideLoading: true
      };
    case SERIALNUMBER_DELETE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case SERIALNUMBER_DELETE_ALL:
      return {
        ...state
      };
    case SERIALNUMBER_DELETE_ALL_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case SERIALNUMBER_DELETE_ALL_FAILED:
      return {
        ...state
      };
    default:
      return state;
  }
}
