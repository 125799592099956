import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';

import {
  loadMedias as loadMediasAction,
  loadMediasSuccess,
  addMediaSuccess,
  updateMediaSuccess
} from '../actions/Media';

import {
  MEDIAS_LOAD,
  MEDIA_ADD,
  MEDIA_UPDATE,
  MEDIA_DELETE,
  MEDIA_DELETE_ALL,
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

const loadMediasRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`media/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`media`, { params })
    .then(data => data)
    .catch(error => error);
}

const addMediaRequest = async (payload) =>
  await Api().post(`media`, payload)
    .then(data => data)
    .catch(error => error);

const updateMediaRequest = async (payload) =>
  await Api().patch(`media/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteMediaRequest = async (id) =>
  await Api().delete(`media/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadMedias({ payload }) {
  try {
    const data = yield call(loadMediasRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadMediasSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadMedias() {
  yield takeEvery(MEDIAS_LOAD, loadMedias);
}

function* loadMedia({ selectedMediaId }) {
  try {
    const data = yield call(loadMediasRequest, selectedMediaId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadMediasSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadMedia() {
  yield takeEvery(MEDIAS_LOAD, loadMedia);
}


function* addMedia({ payload }) {
  try {
    const data = yield call(addMediaRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addMediaSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddMedia() {
  yield takeEvery(MEDIA_ADD, addMedia);
}

function* updateMedia({ payload }) {
  try {
    const data = yield call(updateMediaRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateMediaSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateMedia() {
  yield takeEvery(MEDIA_UPDATE, updateMedia);
}

function* deleteMedia({ payload }) {
  try {
    const data = yield call(deleteMediaRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadMediasAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllMedias({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteMediaRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadMediasAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteMedia() {
  yield takeEvery(MEDIA_DELETE, deleteMedia);
}

function* processDeleteAllMedias() {
  yield takeEvery(MEDIA_DELETE_ALL, deleteAllMedias);
}

export default function* MediaSagas() {
  yield all([fork(processLoadMedias),
  fork(processLoadMedia),
  fork(processAddMedia),
  fork(processUpdateMedia),
  fork(processDeleteAllMedias),
  fork(processDeleteMedia)]);
}
