import {
  USER_SEARCH,
  USER_ASSIGN_ROLES,
  USER_LOCK,
  USER_SEARCH_SUCCESS,
  USER_ASSIGN_ROLES_SUCCESS,
  USER_ASSIGN_EMPLOYEE_SUCCESS,
  USER_LOCK_SUCCESS,
  USER_UNLOCK,
  USER_UNLOCK_SUCCESS,
  USER_CHANGE_LOCK_RELOAD,
  USER_ASSIGN_EMPLOYEE
} from '../constants/ActionTypes';
import { PAGE_SIZES } from '../constants/AppConstant';

const INIT_STATE = {
  paging: {
    records: [],
    offset: 0,
    limit: PAGE_SIZES,
    totalPages: 1,
    totalRecords: 0
  },
  selectedUser: null,
  loader: false,
  lockReload: false,
  assignEmployee: false,
  filter: {
    offset: 0,
    limit: PAGE_SIZES,
    orderBy: 'userName',
    filterGroups: [{
      filters: [{
        // property: 'userName',
        // operator: 'contains',
        // value: ''

        property: 'lockoutEnabled',
        operator: '=',
        value: false
      }]
    }]
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case USER_SEARCH: {
      return {
        ...state,
        loader: true,
        filter: action.payload
      }
    }
    case USER_SEARCH_SUCCESS: {
      return {
        ...state,
        loader: false,
        paging: action.payload
      }
    }
    case USER_ASSIGN_ROLES: {
      return {
        ...state,
        loader: true
      }
    }
    case USER_ASSIGN_ROLES_SUCCESS: {
      return {
        ...state,
        loader: false
      }
    }
    case USER_ASSIGN_EMPLOYEE_SUCCESS: {
      return {
        ...state,
        loader: false,
        lockReload: true
      }
    }
    case USER_LOCK: {
      return {
        ...state,
        loader: true
      }
    }
    case USER_LOCK_SUCCESS: {
      return {
        ...state,
        loader: false,
        lockReload: true
      }
    }
    case USER_UNLOCK: {
      return {
        ...state,
        loader: true
      }
    }
    case USER_UNLOCK_SUCCESS: {
      return {
        ...state,
        loader: false,
        lockReload: true
      }
    }
    case USER_CHANGE_LOCK_RELOAD: {
      return {
        ...state,
        lockReload: false
      }
    }
    default:
      return state;
  }
}
