import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadTutionFeeHistories as loadTutionFeeHistoriesAction,
  loadTutionFeeHistoriesSuccess,
  addTutionFeeHistorySuccess,
  updateTutionFeeHistorySuccess,
  exportExcelTutionFeeHistories,
  exportExcelTutionFeeHistoriesSuccess,
  exportExcelTutionFeeHistoriesFailed
} from '../actions/TutionFeeHistory';

import {
  TUTIONFEEHISTORIES_LOAD,
  TUTIONFEEHISTORY_ADD,
  TUTIONFEEHISTORY_UPDATE,
  TUTIONFEEHISTORY_DELETE,
  TUTIONFEEHISTORY_DELETE_ALL,
  TUTIONFEEHISTORIES_EXPORT_EXCEL,
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getTutionFeeHistoryById = (id, callback) => {
  Api().get('tution-fee-history/' + id).then(response => {
    callback(response.data);
  })
}

export const getAllTutionFeeHistories = (callback) => {
  Api().post('tution-fee-history/search', { limit: GET_ALL_SIZE })
    .then(response => {
      callback(response.data.records);
    })
}

const loadTutionFeeHistoriesRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`tution-fee-history/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`tution-fee-history`, { params })
    .then(data => data)
    .catch(error => error);
}


const addTutionFeeHistoryRequest = async (payload) =>
  await Api().post(`tution-fee-history`, payload)
    .then(data => data)
    .catch(error => error);

const updateTutionFeeHistoryRequest = async (payload) =>
  await Api().patch(`tution-fee-history/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteTutionFeeHistoryRequest = async (id) =>
  await Api().delete(`tution-fee-history/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadTutionFeeHistories({ payload }) {
  try {
    const data = yield call(loadTutionFeeHistoriesRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadTutionFeeHistoriesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadTutionFeeHistories() {
  yield takeEvery(TUTIONFEEHISTORIES_LOAD, loadTutionFeeHistories);
}

function* loadTutionFeeHistory({ selectedTutionFeeHistoryId }) {
  try {
    const data = yield call(loadTutionFeeHistoriesRequest, selectedTutionFeeHistoryId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadTutionFeeHistoriesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadTutionFeeHistory() {
  yield takeEvery(TUTIONFEEHISTORIES_LOAD, loadTutionFeeHistory);
}


function* addTutionFeeHistory({ payload }) {
  try {
    const data = yield call(addTutionFeeHistoryRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addTutionFeeHistorySuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddTutionFeeHistory() {
  yield takeEvery(TUTIONFEEHISTORY_ADD, addTutionFeeHistory);
}


function* updateTutionFeeHistory({ payload }) {
  try {
    const data = yield call(updateTutionFeeHistoryRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateTutionFeeHistorySuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateTutionFeeHistory() {
  yield takeEvery(TUTIONFEEHISTORY_UPDATE, updateTutionFeeHistory);
}

function* deleteTutionFeeHistory({ payload }) {
  try {
    const data = yield call(deleteTutionFeeHistoryRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadTutionFeeHistoriesAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllTutionFeeHistories({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteTutionFeeHistoryRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadTutionFeeHistoriesAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteTutionFeeHistory() {
  yield takeEvery(TUTIONFEEHISTORY_DELETE, deleteTutionFeeHistory);
}

function* processDeleteAllTutionFeeHistories() {
  yield takeEvery(TUTIONFEEHISTORY_DELETE_ALL, deleteAllTutionFeeHistories);
}

const exportTutionFeeHistoriesRequest = async (params) => {
  return await Api({responseType: 'blob'}).post(`tution-fee-history/export/excel`, params)
    .then(data => data)
    .catch(error => error);
}

function* exportTutionFeeHistories({ payload }) {
  try {
    const data = yield call(exportTutionFeeHistoriesRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(exportExcelTutionFeeHistoriesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processExportTutionFeeHistories() {
  yield takeEvery(TUTIONFEEHISTORIES_EXPORT_EXCEL, exportTutionFeeHistories);
}

export default function* TutionFeeHistorySagas() {
  yield all([fork(processLoadTutionFeeHistories),
  fork(processLoadTutionFeeHistory),
  fork(processAddTutionFeeHistory),
  fork(processUpdateTutionFeeHistory),
  fork(processDeleteAllTutionFeeHistories),
  fork(processDeleteTutionFeeHistory),
  fork(processExportTutionFeeHistories)]);
}
