import {
  TUTIONFEEHISTORIES_SELECT_ALL,
  TUTIONFEEHISTORIES_UNSELECT_ALL,
  TUTIONFEEHISTORY_SELECTED,
  TUTIONFEEHISTORIES_LOAD,
  TUTIONFEEHISTORIES_LOAD_SUCCESS,
  TUTIONFEEHISTORIES_LOAD_FAILED,
  TUTIONFEEHISTORY_LOAD,
  TUTIONFEEHISTORY_LOAD_SUCCESS,
  TUTIONFEEHISTORY_LOAD_FAILED,
  TUTIONFEEHISTORY_ADD,
  TUTIONFEEHISTORY_ADD_SUCCESS,
  TUTIONFEEHISTORY_ADD_FAILED,
  TUTIONFEEHISTORY_UPDATE,
  TUTIONFEEHISTORY_UPDATE_SUCCESS,
  TUTIONFEEHISTORY_UPDATE_FAILED,
  TUTIONFEEHISTORY_DELETE,
  TUTIONFEEHISTORY_DELETE_SUCCESS,
  TUTIONFEEHISTORY_DELETE_FAILED,
  TUTIONFEEHISTORY_DELETE_ALL,
  TUTIONFEEHISTORY_DELETE_ALL_SUCCESS,
  TUTIONFEEHISTORY_DELETE_ALL_FAILED,
  TUTIONFEEHISTORIES_EXPORT_EXCEL,
  TUTIONFEEHISTORIES_EXPORT_EXCEL_SUCCESS,
  TUTIONFEEHISTORIES_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';

export const selectAllTutionFeeHistories = () => {
  return {
    type: TUTIONFEEHISTORIES_SELECT_ALL
  }
}

export const unselectAllTutionFeeHistories = () => {
  return {
    type: TUTIONFEEHISTORIES_UNSELECT_ALL
  }
}

export const selectTutionFeeHistory = data => {
  return {
    type: TUTIONFEEHISTORY_SELECTED,
    payload: data
  }
}

export const loadTutionFeeHistories = (data) => {
  return {
    type: TUTIONFEEHISTORIES_LOAD,
    payload: data
  };
}

export const loadTutionFeeHistoriesSuccess = (data) => {
  return {
    type: TUTIONFEEHISTORIES_LOAD_SUCCESS,
    payload: data
  };
}

export const loadTutionFeeHistoriesFailed = () => {
  return {
    type: TUTIONFEEHISTORIES_LOAD_FAILED
  };
}

export const loadTutionFeeHistory = () => {
  return {
    type: TUTIONFEEHISTORY_LOAD
  };
}

export const loadTutionFeeHistorySuccess = (data) => {
  return {
    type: TUTIONFEEHISTORY_LOAD_SUCCESS,
    payload: data
  };
}


export const loadTutionFeeHistoryFailed = () => {
  return {
    type: TUTIONFEEHISTORY_LOAD_FAILED
  };
}


export const addTutionFeeHistory = (data) => {
  return {
    type: TUTIONFEEHISTORY_ADD,
    payload: data
  };
}


export const addTutionFeeHistorySuccess = (filter) => {
  return {
    type: TUTIONFEEHISTORY_ADD_SUCCESS,
    message: 'TutionFeeHistory added successfully.',
    payload: filter
  };
}

export const addTutionFeeHistoryFailed = () => {
  return {
    type: TUTIONFEEHISTORY_ADD_FAILED
  };
}

export const updateTutionFeeHistory = (data) => {
  return {
    type: TUTIONFEEHISTORY_UPDATE,
    payload: data
  };
}


export const updateTutionFeeHistorySuccess = (data) => {
  return {
    type: TUTIONFEEHISTORY_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateTutionFeeHistoryFailed = (data) => {
  return {
    type: TUTIONFEEHISTORY_UPDATE_FAILED,
    payload: data
  };
}

export const deleteTutionFeeHistory = (data) => {
  return {
    type: TUTIONFEEHISTORY_DELETE,
    payload: data
  };
}

export const deleteTutionFeeHistorySuccess = () => {
  return {
    type: TUTIONFEEHISTORY_DELETE_SUCCESS,
    message: 'TutionFeeHistory deleted successfully.'
  };
}

export const deleteTutionFeeHistoryFailed = () => {
  return {
    type: TUTIONFEEHISTORY_DELETE_FAILED
  };
}

export const deleteAllTutionFeeHistories = (data) => {
  return {
    type: TUTIONFEEHISTORY_DELETE_ALL,
    payload: data
  };
}

export const deleteAllTutionFeeHistoriesSuccess = () => {
  return {
    type: TUTIONFEEHISTORY_DELETE_ALL_SUCCESS,
    message: 'TutionFeeHistory all deleted successfully.'
  };
}

export const deleteAllTutionFeeHistoriesFailed = () => {
  return {
    type: TUTIONFEEHISTORY_DELETE_ALL_FAILED
  };
}

export const exportExcelTutionFeeHistories = (filter) => {
  return {
    type: TUTIONFEEHISTORIES_EXPORT_EXCEL,
    payload: filter
  }
}

export const exportExcelTutionFeeHistoriesSuccess = (data) => {
  return {
    type: TUTIONFEEHISTORIES_EXPORT_EXCEL_SUCCESS,
    payload: data
  };
}

export const exportExcelTutionFeeHistoriesFailed = () => {
  return {
    type: TUTIONFEEHISTORIES_EXPORT_EXCEL_FAILED
  };
}
