import {
  QUOTATIONS_SELECT_ALL,
  QUOTATIONS_UNSELECT_ALL,
  QUOTATION_SELECTED,
  QUOTATIONS_LOAD,
  QUOTATIONS_LOAD_SUCCESS,
  QUOTATIONS_LOAD_FAILED,
  QUOTATION_LOAD,
  QUOTATION_LOAD_SUCCESS,
  QUOTATION_LOAD_FAILED,
  QUOTATION_ADD,
  QUOTATION_ADD_SUCCESS,
  QUOTATION_ADD_FAILED,
  QUOTATION_UPDATE,
  QUOTATION_UPDATE_SUCCESS,
  QUOTATION_UPDATE_FAILED,
  QUOTATION_DELETE,
  QUOTATION_DELETE_SUCCESS,
  QUOTATION_DELETE_FAILED,
  QUOTATION_DELETE_ALL,
  QUOTATION_DELETE_ALL_SUCCESS,
  QUOTATION_DELETE_ALL_FAILED
} from '../constants/ActionTypes';
import { PAGE_SIZES } from '../constants/AppConstant';

const INIT_STATE = {
  paging: {
    records: [],
    offset: 0,
    limit: PAGE_SIZES,
    totalRecords: 0
  },
  selectedQuotation: null,
  selectedQuotationId: null,
  message: null,
  messageType: 'info',
  filter: {
    offset: 0,
    limit: PAGE_SIZES,
    orderBy: 'id',
    descending: true,
    filterGroups: null
  },
  hideLoading: true
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case QUOTATIONS_SELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = true;
            return data;
          })
        }
      }
    case QUOTATIONS_UNSELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = false;
            return data;
          })
        }
      }
    case QUOTATION_SELECTED:
      return {
        ...state,
        selectedQuotation: action.payload,
        selectedQuotationId: action.payload.id
      };
    case QUOTATIONS_LOAD:
      return {
        ...state,
        filter: action.payload ? action.payload : state.filter
      };
    case QUOTATIONS_LOAD_SUCCESS:
      return {
        ...state,
        paging: action.payload
      };
    case QUOTATIONS_LOAD_FAILED:
      return {
        ...state
      };
    case QUOTATION_LOAD:
      return {
        ...state
      };
    case QUOTATION_LOAD_SUCCESS:
      return {
        ...state
      };
    case QUOTATION_LOAD_FAILED:
      return {
        ...state
      };
    case QUOTATION_ADD:
      return {
        ...state,
        hideLoading: false
      };
    case QUOTATION_ADD_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case QUOTATION_ADD_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case QUOTATION_UPDATE:
      return {
        ...state,
        hideLoading: false
      };
    case QUOTATION_UPDATE_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case QUOTATION_UPDATE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case QUOTATION_DELETE:
      return {
        ...state,
        hideLoading: false
      };
    case QUOTATION_DELETE_SUCCESS:
      return {
        ...state,
        message: action.message,
        hideLoading: true
      };
    case QUOTATION_DELETE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case QUOTATION_DELETE_ALL:
      return {
        ...state
      };
    case QUOTATION_DELETE_ALL_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case QUOTATION_DELETE_ALL_FAILED:
      return {
        ...state
      };
    default:
      return state;
  }
}
