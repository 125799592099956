import {all} from "redux-saga/effects";
import settingSagas from './Setting';
import companySagas from './Company';
import departmentSagas from './Department';
import employeeSagas from './Employee';
import personalPaperSagas from './PersonalPaper';
import paymentSagas from './Payment';
import contractSagas from './Contract';
import quotationSagas from './Quotation';
import workLocationSagas from './WorkLocation';
import studentSagas from './Student';
import leaveTrackSagas from './LeaveTrack';
import leaveTrackHistorySagas from './LeaveTrackHistory';
import sampleSages from './Sample';
import authSaga from './Auth';
import userSagas from './User';
import roleSagas from './Role';
import jobSagas from './Job';
import mailSagas from './Mail';
import mailToUserSagas from './MailToUser';
import templateSagas from './Template';
import invoiceSagas from './Invoice';
import serialNumberSagas from './SerialNumber';
import albumSagas from './Album';
import mediaSagas from './Media';
import classRoomSagas from './ClassRoom';
import tutionFeeSagas from './TutionFee';
import tutionFeeHistorySagas from './TutionFeeHistory';
import tutionFeePaymentSagas from './TutionFeePayment';
import TutionFeePaymentSagas from "./TutionFeePayment";
import TeacherClassSagas from "./TeacherClass";

export default function* rootSaga(getState) {
  yield all([
    settingSagas(),
    companySagas(),
    departmentSagas(),
    employeeSagas(),
    personalPaperSagas(),
    paymentSagas(),
    contractSagas(),
    quotationSagas(),
    workLocationSagas(),
    studentSagas(),
    leaveTrackSagas(),
    leaveTrackHistorySagas(),
    sampleSages(),
    authSaga(),
    userSagas(),
    roleSagas(),
    jobSagas(),
    mailSagas(),
    mailToUserSagas(),
    templateSagas(),
    invoiceSagas(),
    serialNumberSagas(),
    albumSagas(),
    mediaSagas(),
    classRoomSagas(),
    tutionFeeSagas(),
    tutionFeeHistorySagas(),
    TutionFeePaymentSagas(),
    TeacherClassSagas()
  ]);
}
