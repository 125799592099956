import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';

import {
  loadLeaveTracks as loadLeaveTracksAction,
  loadLeaveTracksSuccess,
  updateLeaveTrackSuccess,
  addLeaveTrackSuccess,
  deleteLeaveTrackSuccess,
  deleteAllLeaveTracksSuccess
} from '../actions/LeaveTrack';

import {
  LEAVETRACKS_LOAD,
  LEAVETRACK_ADD,
  LEAVETRACK_UPDATE,
  LEAVETRACK_DELETE,
  LEAVETRACK_DELETE_ALL,
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getLeaveTrackById = (id, callback) => {
  Api().get('leave-track/' + id).then(response => {
    callback(response.data);
  })
}

const loadLeaveTracksRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`leave-track/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`leave-track`, { params })
    .then(data => data)
    .catch(error => error);
}

const addLeaveTrackRequest = async (payload) =>
  await Api().post(`leave-track`, payload)
    .then(data => data)
    .catch(error => error);

const updateLeaveTrackRequest = async (payload) =>
  await Api().patch(`leave-track/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteLeaveTrackRequest = async (id) =>
  await Api().delete(`leave-track/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadLeaveTracks({ payload }) {
  try {
    const data = yield call(loadLeaveTracksRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadLeaveTracksSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadLeaveTracks() {
  yield takeEvery(LEAVETRACKS_LOAD, loadLeaveTracks);
}

function* loadLeaveTrack({ selectedLeaveTrackId }) {
  try {
    const data = yield call(loadLeaveTracksRequest, selectedLeaveTrackId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadLeaveTracksSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadLeaveTrack() {
  yield takeEvery(LEAVETRACKS_LOAD, loadLeaveTrack);
}

function* addLeaveTrack({ payload }) {
  try {
    const data = yield call(addLeaveTrackRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addLeaveTrackSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddLeaveTrack() {
  yield takeEvery(LEAVETRACK_ADD, addLeaveTrack);
}

function* updateLeaveTrack({ payload }) {
  try {
    const data = yield call(updateLeaveTrackRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateLeaveTrackSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateLeaveTrack() {
  yield takeEvery(LEAVETRACK_UPDATE, updateLeaveTrack);
}

function* deleteLeaveTrack({ payload }) {
  try {
    const data = yield call(deleteLeaveTrackRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(deleteLeaveTrackSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllLeaveTracks({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteLeaveTrackRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(deleteAllLeaveTracksSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteLeaveTrack() {
  yield takeEvery(LEAVETRACK_DELETE, deleteLeaveTrack);
}

function* processDeleteAllLeaveTracks() {
  yield takeEvery(LEAVETRACK_DELETE_ALL, deleteAllLeaveTracks);
}

export default function* LeaveTrackSagas() {
  yield all([fork(processLoadLeaveTracks),
  fork(processLoadLeaveTrack),
  fork(processAddLeaveTrack),
  fork(processUpdateLeaveTrack),
  fork(processDeleteAllLeaveTracks),
  fork(processDeleteLeaveTrack)]);
}
