import {
    TUTIONFEEPAYMENTS_SELECT_ALL,
    TUTIONFEEPAYMENTS_UNSELECT_ALL,
    TUTIONFEEPAYMENT_SELECTED,
    TUTIONFEEPAYMENTS_LOAD,
    TUTIONFEEPAYMENTS_LOAD_SUCCESS,
    TUTIONFEEPAYMENTS_LOAD_FAILED,
    TUTIONFEEPAYMENT_LOAD,
    TUTIONFEEPAYMENT_LOAD_SUCCESS,
    TUTIONFEEPAYMENT_LOAD_FAILED,
    TUTIONFEEPAYMENT_ADD,
    TUTIONFEEPAYMENT_ADD_SUCCESS,
    TUTIONFEEPAYMENT_ADD_FAILED,
    TUTIONFEEPAYMENT_UPDATE,
    TUTIONFEEPAYMENT_UPDATE_SUCCESS,
    TUTIONFEEPAYMENT_UPDATE_FAILED,
    TUTIONFEEPAYMENT_DELETE,
    TUTIONFEEPAYMENT_DELETE_SUCCESS,
    TUTIONFEEPAYMENT_DELETE_FAILED,
    TUTIONFEEPAYMENT_DELETE_ALL,
    TUTIONFEEPAYMENT_DELETE_ALL_SUCCESS,
    TUTIONFEEPAYMENT_DELETE_ALL_FAILED,
  TUTIONFEEPAYMENTS_EXPORT_EXCEL,
  TUTIONFEEPAYMENTS_EXPORT_EXCEL_SUCCESS,
  TUTIONFEEPAYMENTS_EXPORT_EXCEL_FAILED
  } from '../constants/ActionTypes';
  
  export const selectAllTutionFeePayments = () => {
    return {
      type: TUTIONFEEPAYMENTS_SELECT_ALL
    }
  }
  
  export const unselectAllTutionFeePayments = () => {
    return {
      type: TUTIONFEEPAYMENTS_UNSELECT_ALL
    }
  }
  
  export const selectTutionFeePayment = data => {
    return {
      type: TUTIONFEEPAYMENT_SELECTED,
      payload: data
    }
  }
  
  export const loadTutionFeePayments = (data) => {
    return {
      type: TUTIONFEEPAYMENTS_LOAD,
      payload: data
    };
  }
  
  export const loadTutionFeePaymentsSuccess = (data) => {
    return {
      type: TUTIONFEEPAYMENTS_LOAD_SUCCESS,
      payload: data
    };
  }
  
  export const loadTutionFeePaymentsFailed = () => {
    return {
      type: TUTIONFEEPAYMENTS_LOAD_FAILED
    };
  }
  
  export const loadTutionFeePayment = () => {
    return {
      type: TUTIONFEEPAYMENT_LOAD
    };
  }
  
  export const loadTutionFeePaymentSuccess = (data) => {
    return {
      type: TUTIONFEEPAYMENT_LOAD_SUCCESS,
      payload: data
    };
  }
  
  
  export const loadTutionFeePaymentFailed = () => {
    return {
      type: TUTIONFEEPAYMENT_LOAD_FAILED
    };
  }
  
  
  export const addTutionFeePayment = (data) => {
    return {
      type: TUTIONFEEPAYMENT_ADD,
      payload: data
    };
  }
  
  
  export const addTutionFeePaymentSuccess = (filter) => {
    return {
      type: TUTIONFEEPAYMENT_ADD_SUCCESS,
      message: 'TutionFeePayment added successfully.',
      payload: filter
    };
  }
  
  export const addTutionFeePaymentFailed = () => {
    return {
      type: TUTIONFEEPAYMENT_ADD_FAILED
    };
  }
  
  export const updateTutionFeePayment = (data) => {
    return {
      type: TUTIONFEEPAYMENT_UPDATE,
      payload: data
    };
  }
  
  
  export const updateTutionFeePaymentSuccess = (data) => {
    return {
      type: TUTIONFEEPAYMENT_UPDATE_SUCCESS,
      payload: data
    };
  }
  
  export const updateTutionFeePaymentFailed = (data) => {
    return {
      type: TUTIONFEEPAYMENT_UPDATE_FAILED,
      payload: data
    };
  }
  
  export const deleteTutionFeePayment = (data) => {
    return {
      type: TUTIONFEEPAYMENT_DELETE,
      payload: data
    };
  }
  
  export const deleteTutionFeePaymentSuccess = () => {
    return {
      type: TUTIONFEEPAYMENT_DELETE_SUCCESS,
      message: 'TutionFeePayment deleted successfully.'
    };
  }
  
  export const deleteTutionFeePaymentFailed = () => {
    return {
      type: TUTIONFEEPAYMENT_DELETE_FAILED
    };
  }
  
  export const deleteAllTutionFeePayments = (data) => {
    return {
      type: TUTIONFEEPAYMENT_DELETE_ALL,
      payload: data
    };
  }
  
  export const deleteAllTutionFeePaymentsSuccess = () => {
    return {
      type: TUTIONFEEPAYMENT_DELETE_ALL_SUCCESS,
      message: 'TutionFeePayment all deleted successfully.'
    };
  }
  
  export const deleteAllTutionFeePaymentsFailed = () => {
    return {
      type: TUTIONFEEPAYMENT_DELETE_ALL_FAILED
    };
  }

  
export const exportExcelTutionFeePayments = (filter) => {
  return {
    type: TUTIONFEEPAYMENTS_EXPORT_EXCEL,
    payload: filter
  } 
}

export const exportExcelTutionFeePaymentsSuccess = (data) => {
  return {
    type: TUTIONFEEPAYMENTS_EXPORT_EXCEL_SUCCESS,
    payload: data
  };
}

export const exportExcelTutionFeePaymentsFailed = () => {
  return {
    type: TUTIONFEEPAYMENTS_EXPORT_EXCEL_FAILED
  };
}
  