import {
  TEACHERCLASSES_SELECT_ALL,
  TEACHERCLASSES_UNSELECT_ALL,
  TEACHERCLASS_SELECTED,
  TEACHERCLASSES_LOAD,
  TEACHERCLASSES_LOAD_SUCCESS,
  TEACHERCLASSES_LOAD_FAILED,
  TEACHERCLASS_LOAD,
  TEACHERCLASS_LOAD_SUCCESS,
  TEACHERCLASS_LOAD_FAILED,
  TEACHERCLASS_ADD,
  TEACHERCLASS_ADD_SUCCESS,
  TEACHERCLASS_ADD_FAILED,
  TEACHERCLASS_UPDATE,
  TEACHERCLASS_UPDATE_SUCCESS,
  TEACHERCLASS_UPDATE_FAILED,
  TEACHERCLASS_DELETE,
  TEACHERCLASS_DELETE_SUCCESS,
  TEACHERCLASS_DELETE_FAILED,
  TEACHERCLASS_DELETE_ALL,
  TEACHERCLASS_DELETE_ALL_SUCCESS,
  TEACHERCLASS_DELETE_ALL_FAILED
} from '../constants/ActionTypes';

export const selectAllTeacherClasses = () => {
  return {
    type: TEACHERCLASSES_SELECT_ALL
  }
}

export const unselectAllTeacherClasses = () => {
  return {
    type: TEACHERCLASSES_UNSELECT_ALL
  }
}

export const selectTeacherClass = data => {
  return {
    type: TEACHERCLASS_SELECTED,
    payload: data
  }
}

export const loadTeacherClasses = (data) => {
  return {
    type: TEACHERCLASSES_LOAD,
    payload: data
  };
}

export const loadTeacherClassesSuccess = (data) => {
  return {
    type: TEACHERCLASSES_LOAD_SUCCESS,
    payload: data
  };
}

export const loadTeacherClassesFailed = () => {
  return {
    type: TEACHERCLASSES_LOAD_FAILED
  };
}

export const loadTeacherClass = () => {
  return {
    type: TEACHERCLASS_LOAD
  };
}

export const loadTeacherClassSuccess = (data) => {
  return {
    type: TEACHERCLASS_LOAD_SUCCESS,
    payload: data
  };
}


export const loadTeacherClassFailed = () => {
  return {
    type: TEACHERCLASS_LOAD_FAILED
  };
}


export const addTeacherClass = (data) => {
  return {
    type: TEACHERCLASS_ADD,
    payload: data
  };
}


export const addTeacherClassSuccess = (filter) => {
  return {
    type: TEACHERCLASS_ADD_SUCCESS,
    message: 'TeacherClass added successfully.',
    payload: filter
  };
}

export const addTeacherClassFailed = () => {
  return {
    type: TEACHERCLASS_ADD_FAILED
  };
}

export const updateTeacherClass = (data) => {
  return {
    type: TEACHERCLASS_UPDATE,
    payload: data
  };
}


export const updateTeacherClassSuccess = (data) => {
  return {
    type: TEACHERCLASS_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateTeacherClassFailed = (data) => {
  return {
    type: TEACHERCLASS_UPDATE_FAILED,
    payload: data
  };
}

export const deleteTeacherClass = (data) => {
  return {
    type: TEACHERCLASS_DELETE,
    payload: data
  };
}

export const deleteTeacherClassSuccess = () => {
  return {
    type: TEACHERCLASS_DELETE_SUCCESS,
    message: 'TeacherClass deleted successfully.'
  };
}

export const deleteTeacherClassFailed = () => {
  return {
    type: TEACHERCLASS_DELETE_FAILED
  };
}

export const deleteAllTeacherClasses = (data) => {
  return {
    type: TEACHERCLASS_DELETE_ALL,
    payload: data
  };
}

export const deleteAllTeacherClassesSuccess = () => {
  return {
    type: TEACHERCLASS_DELETE_ALL_SUCCESS,
    message: 'TeacherClass all deleted successfully.'
  };
}

export const deleteAllTeacherClassesFailed = () => {
  return {
    type: TEACHERCLASS_DELETE_ALL_FAILED
  };
}
