import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadTutionFeePayments as loadTutionFeePaymentsAction,
  loadTutionFeePaymentsSuccess,
  addTutionFeePaymentSuccess,
  updateTutionFeePaymentSuccess,
  exportExcelTutionFeePayments,
  exportExcelTutionFeePaymentsSuccess,
  exportExcelTutionFeePaymentsFailed
} from '../actions/TutionFeePayment';

import {
  TUTIONFEEPAYMENTS_LOAD,
  TUTIONFEEPAYMENT_ADD,
  TUTIONFEEPAYMENT_UPDATE,
  TUTIONFEEPAYMENT_DELETE,
  TUTIONFEEPAYMENT_DELETE_ALL,
  TUTIONFEEPAYMENTS_EXPORT_EXCEL,
  TUTIONFEEPAYMENTS_EXPORT_EXCEL_SUCCESS,
  TUTIONFEEPAYMENTS_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getTutionFeePaymentById = (id, callback) => {
  Api().get('tution-fee-payment/' + id).then(response => {
    callback(response.data);
  })
}

export const getAllTutionFeePayments = (callback) => {
  Api().post('tution-fee-payment/search', { limit: GET_ALL_SIZE })
    .then(response => {
      callback(response.data.records);        
    })
}

const loadTutionFeePaymentsRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`tution-fee-payment/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`tution-fee-payment`, { params })
    .then(data => data)
    .catch(error => error);
}


const addTutionFeePaymentRequest = async (payload) =>
  await Api().post(`tution-fee-payment`, payload)
    .then(data => data)
    .catch(error => error);

const updateTutionFeePaymentRequest = async (payload) =>
  await Api().patch(`tution-fee-payment/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteTutionFeePaymentRequest = async (id) =>
  await Api().delete(`tution-fee-payment/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadTutionFeePayments({ payload }) {
  try {
    const data = yield call(loadTutionFeePaymentsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadTutionFeePaymentsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadTutionFeePayments() {
  yield takeEvery(TUTIONFEEPAYMENTS_LOAD, loadTutionFeePayments);
}

function* loadTutionFeePayment({ selectedTutionFeePaymentId }) {
  try {
    const data = yield call(loadTutionFeePaymentsRequest, selectedTutionFeePaymentId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadTutionFeePaymentsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadTutionFeePayment() {
  yield takeEvery(TUTIONFEEPAYMENTS_LOAD, loadTutionFeePayment);
}


function* addTutionFeePayment({ payload }) {
  try {
    const data = yield call(addTutionFeePaymentRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addTutionFeePaymentSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddTutionFeePayment() {
  yield takeEvery(TUTIONFEEPAYMENT_ADD, addTutionFeePayment);
}


function* updateTutionFeePayment({ payload }) {
  try {
    const data = yield call(updateTutionFeePaymentRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateTutionFeePaymentSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateTutionFeePayment() {
  yield takeEvery(TUTIONFEEPAYMENT_UPDATE, updateTutionFeePayment);
}

function* deleteTutionFeePayment({ payload }) {
  try {
    const data = yield call(deleteTutionFeePaymentRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadTutionFeePaymentsAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllTutionFeePayments({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteTutionFeePaymentRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadTutionFeePaymentsAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteTutionFeePayment() {
  yield takeEvery(TUTIONFEEPAYMENT_DELETE, deleteTutionFeePayment);
}

function* processDeleteAllTutionFeePayments() {
  yield takeEvery(TUTIONFEEPAYMENT_DELETE_ALL, deleteAllTutionFeePayments);
}


const exportTutionFeePaymentsRequest = async (params) => {
  return await Api({responseType: 'blob'}).post(`tution-fee-payment/export/excel`, params)
    .then(data => data)
    .catch(error => error);
}

function* exportTutionFeePayments({ payload }) {
  try {
    const data = yield call(exportTutionFeePaymentsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(exportExcelTutionFeePaymentsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processExportTutionFeePayments() {
  yield takeEvery(TUTIONFEEPAYMENTS_EXPORT_EXCEL, exportTutionFeePayments);
}

export default function* TutionFeePaymentSagas() {
  yield all([fork(processLoadTutionFeePayments),
  fork(processLoadTutionFeePayment),
  fork(processAddTutionFeePayment),
  fork(processUpdateTutionFeePayment),
  fork(processDeleteAllTutionFeePayments),
  fork(processDeleteTutionFeePayment),
  fork(processExportTutionFeePayments)
]);
}
