import {
  COMPANIES_SELECT_ALL,
  COMPANIES_UNSELECT_ALL,
  COMPANY_SELECTED,
  COMPANIES_LOAD,
  COMPANIES_LOAD_SUCCESS,
  COMPANIES_LOAD_FAILED,
  COMPANY_LOAD,
  COMPANY_LOAD_SUCCESS,
  COMPANY_LOAD_FAILED,
  COMPANY_ADD,
  COMPANY_ADD_SUCCESS,
  COMPANY_ADD_FAILED,
  COMPANY_UPDATE,
  COMPANY_UPDATE_SUCCESS,
  COMPANY_UPDATE_FAILED,
  COMPANY_DELETE,
  COMPANY_DELETE_SUCCESS,
  COMPANY_DELETE_FAILED,
  COMPANY_DELETE_ALL,
  COMPANY_DELETE_ALL_SUCCESS,
  COMPANY_DELETE_ALL_FAILED
} from '../constants/ActionTypes';
import { PAGE_SIZES } from '../constants/AppConstant';

const INIT_STATE = {
  paging: {
    records: [],
    offset: 0,
    limit: PAGE_SIZES,
    totalRecords: 0
  },
  selectedCompany: null,
  selectedCompanyId: null,
  message: null,
  messageType: 'info',
  filter: {
    offset: 0,
    limit: PAGE_SIZES,
    orderBy: 'id',
    filterGroups: null
  },
  hideLoading: true
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case COMPANIES_SELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = true;
            return data;
          })
        }
      }
    case COMPANIES_UNSELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = false;
            return data;
          })
        }
      }
    case COMPANY_SELECTED:
      return {
        ...state,
        selectedCompany: action.payload,
        selectedCompanyId: action.payload.id
      };
    case COMPANIES_LOAD:
      return {
        ...state,
        filter: action.payload ? action.payload : state.filter,
        hideLoading: false
      };
    case COMPANIES_LOAD_SUCCESS:
      return {
        ...state,
        paging: action.payload,
        selectedCompany: action.payload.records.length ? action.payload.records[0] : null,
        hideLoading: true
      };
    case COMPANIES_LOAD_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case COMPANY_LOAD:
      return {
        ...state,
        hideLoading: false
      };
    case COMPANY_LOAD_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case COMPANY_LOAD_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case COMPANY_ADD:
      return {
        ...state,
        hideLoading: false
      };
    case COMPANY_ADD_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case COMPANY_ADD_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case COMPANY_UPDATE:
      return {
        ...state,
        hideLoading: false
      };
    case COMPANY_UPDATE_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case COMPANY_UPDATE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case COMPANY_DELETE:
      return {
        ...state,
        hideLoading: false
      };
    case COMPANY_DELETE_SUCCESS:
      return {
        ...state,
        message: action.message,
        hideLoading: true
      };
    case COMPANY_DELETE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case COMPANY_DELETE_ALL:
      return {
        ...state,
        hideLoading: false
      };
    case COMPANY_DELETE_ALL_SUCCESS:
      return {
        ...state,
        message: action.message,
        hideLoading: true
      };
    case COMPANY_DELETE_ALL_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    default:
      return state;
  }
}
