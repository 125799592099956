import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadClassRooms as loadClassRoomsAction,
  loadClassRoomsSuccess,
  addClassRoomSuccess,
  updateClassRoomSuccess
} from '../actions/ClassRoom';

import {
  CLASSROOMS_LOAD,
  CLASSROOM_ADD,
  CLASSROOM_UPDATE,
  CLASSROOM_DELETE,
  CLASSROOM_DELETE_ALL,
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getClassRoomById = (id, callback) => {
  Api().get('class-room/' + id).then(response => {
    callback(response.data);
  })
}

export const getAllClassRooms = (callback) => {
  Api().post('class-room/search', { limit: GET_ALL_SIZE })
    .then(response => {
      callback(response.data.records);        
    })
}

export const getClassRoomsByCompany = (type, company, callback) => {
  Api().post('class-room/search', {
    limit: GET_ALL_SIZE,
    filterGroups: [{
      filters: [{
        property: 'companyId',
        operator: 'equal',
        value: type === 'firstLoad' ? company.id : company.companyId
      }]
    }]
  }).then(response => {
    callback(response.data.records);    
  })
}

const loadClassRoomsRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`class-room/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`class-room`, { params })
    .then(data => data)
    .catch(error => error);
}


const addClassRoomRequest = async (payload) =>
  await Api().post(`class-room`, payload)
    .then(data => data)
    .catch(error => error);

const updateClassRoomRequest = async (payload) =>
  await Api().patch(`class-room/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteClassRoomRequest = async (id) =>
  await Api().delete(`class-room/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadClassRooms({ payload }) {
  try {
    const data = yield call(loadClassRoomsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadClassRoomsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}


function* processLoadClassRooms() {
  yield takeEvery(CLASSROOMS_LOAD, loadClassRooms);
}

function* loadClassRoom({ selectedClassRoomId }) {
  try {
    const data = yield call(loadClassRoomsRequest, selectedClassRoomId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadClassRoomsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadClassRoom() {
  yield takeEvery(CLASSROOMS_LOAD, loadClassRoom);
}


function* addClassRoom({ payload }) {
  try {
    const data = yield call(addClassRoomRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addClassRoomSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddClassRoom() {
  yield takeEvery(CLASSROOM_ADD, addClassRoom);
}


function* updateClassRoom({ payload }) {
  try {
    const data = yield call(updateClassRoomRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateClassRoomSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateClassRoom() {
  yield takeEvery(CLASSROOM_UPDATE, updateClassRoom);
}

function* deleteClassRoom({ payload }) {
  try {
    const data = yield call(deleteClassRoomRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadClassRoomsAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllClassRooms({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteClassRoomRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadClassRoomsAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteClassRoom() {
  yield takeEvery(CLASSROOM_DELETE, deleteClassRoom);
}

function* processDeleteAllClassRooms() {
  yield takeEvery(CLASSROOM_DELETE_ALL, deleteAllClassRooms);
}

export default function* ClassRoomSagas() {
  yield all([fork(processLoadClassRooms),
  fork(processLoadClassRoom),
  fork(processAddClassRoom),
  fork(processUpdateClassRoom),
  fork(processDeleteAllClassRooms),
  fork(processDeleteClassRoom)]);
}
