import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api from './Api';

import {
    searchUsersSuccess,
    assignUserToRolesSuccess,
    assignEmployeeToUserSuccess,
    lockUserSuccess
} from '../actions/User';

import {
	USER_SEARCH,
	USER_ASSIGN_ROLES,
	USER_LOCK,
    SAVE_USER,
    USER_ASSIGN_EMPLOYEE,
} from '../constants/ActionTypes';

import {
    userSignOut
  } from '../actions/Auth';
import { saveUser, saveUserSuccess } from "../actions/Common";

const getUserByIdRequest = async (id) => {
  return await Api().get('user/' + id)
    .then(response => response)
    .catch(error => error);
}

const searchUsersRequest = async (params) => {
    return await Api().post(`user/search`, params)
            .then(data => data)
            .catch(error => error);
}

const assignUserToRolesRequest = async (params) => {
    return await Api().post(`user/assign-roles`, params)
            .then(data => data)
            .catch(error => error);
}

const assignEmployeeToUserRequest = async (params) => {
    return await Api().post(`user/assign-employee`, params)
            .then(data => data)
            .catch(error => error);
}

const lockUserRequest = async (params) => {
    return await Api().post(`user/lock`, params)
            .then(data => data)
            .catch(error => error);
}

function* searchUsers({ payload }) {
    try {
        const data = yield call(searchUsersRequest, payload);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(searchUsersSuccess(data.data));
    } catch (error) {
    }
}

function* assignUserToRoles({ payload }) {
    try {
        const data = yield call(assignUserToRolesRequest, payload.info);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(searchUsers({ payload: payload.filter }));
    } catch (error) {
    }
}

function* assignEmployeeToUser({ payload }) {
    try {
        const data = yield call(assignEmployeeToUserRequest, payload);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(assignEmployeeToUserSuccess());
    } catch (error) {
    }
}

function* lockUser({ payload }) {
    try {
        const data = yield call(lockUserRequest, payload);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(lockUserSuccess());
    } catch (error) {
    }
}

function* getUserById({ payload }) {
    try {
        const data = yield call(getUserByIdRequest, payload);
        data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
        yield put(saveUserSuccess(data.data ? data.data : {}));
    } catch (error) {
    }
}

export const getUser = (id, callback) => {
    Api().get('user/' + id).then(response => {
      callback(response.data);
    })
  }

function* processSearchUsers() {
    yield takeEvery(USER_SEARCH, searchUsers);
}

function* processAssignUserToRoles() {
    yield takeEvery(USER_ASSIGN_ROLES, assignUserToRoles);
}

function* processAssignEmployeeToUser() {
    yield takeEvery(USER_ASSIGN_EMPLOYEE, assignEmployeeToUser);
}

function* processLockUser() {
    yield takeEvery(USER_LOCK, lockUser);
}

function* processGetUserById() {
    yield takeEvery(SAVE_USER, getUserById);
}

export default function* userSagas() {
    yield all([fork(processSearchUsers),
    fork(processAssignUserToRoles),
    fork(processAssignEmployeeToUser),
    fork(processLockUser),
    fork(processGetUserById)]);
}
