import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadTeacherClasses as loadTeacherClassesAction,
  loadTeacherClassesSuccess,
  addTeacherClassSuccess,
  updateTeacherClassSuccess
} from '../actions/TeacherClass';

import {
  TEACHERCLASSES_LOAD,
  TEACHERCLASS_ADD,
  TEACHERCLASS_UPDATE,
  TEACHERCLASS_DELETE,
  TEACHERCLASS_DELETE_ALL,
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getTeacherClassById = (id, callback) => {
  Api().get('teacher-class/' + id).then(response => {
    callback(response.data);
  })
}

export const getTeacherClassByClassRoomId = (classRoomId, callback) => {
  Api().post('teacher-class/search', { 
    offset: 0,
    orderBy: "teacherId",
    descending: true,
    filterGroups: [{
      filters: [{
        property: 'classRoomId',
        operator: '=',
        value: classRoomId
      }]
    }]
  })
    .then(response => {
      callback(response.data.records);
    })
}

export const getAllTeacherClasses = (callback) => {
  Api().post('teacher-class/search', { limit: GET_ALL_SIZE })
    .then(response => {
      callback(response.data.records);        
    })
}

export const getMaxTeacherClassCodeSorted = (callback) => {
  Api().post('teacher-class/search', { offset: 0,
    limit: 1,
    orderBy: "codeSorted desc"
  })
    .then(response => {
      let codeSorted = 0;
      let records = response.data.records;
      if (records.length) {
        codeSorted = records[0].codeSorted;
      }
      callback(codeSorted);
    })
}

const loadTeacherClassesRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`teacher-class/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`teacher-class`, { params })
    .then(data => data)
    .catch(error => error);
}


const addTeacherClassRequest = async (payload) =>
  await Api().post(`teacher-class`, payload)
    .then(data => data)
    .catch(error => error);

const updateTeacherClassRequest = async (payload) =>
  await Api().patch(`teacher-class/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteTeacherClassRequest = async (id) =>
  await Api().delete(`teacher-class/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadTeacherClasses({ payload }) {
  try {
    const data = yield call(loadTeacherClassesRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadTeacherClassesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadTeacherClasses() {
  yield takeEvery(TEACHERCLASSES_LOAD, loadTeacherClasses);
}

function* loadTeacherClass({ selectedTeacherClassId }) {
  try {
    const data = yield call(loadTeacherClassesRequest, selectedTeacherClassId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadTeacherClassesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadTeacherClass() {
  yield takeEvery(TEACHERCLASSES_LOAD, loadTeacherClass);
}


function* addTeacherClass({ payload }) {
  try {
    const data = yield call(addTeacherClassRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addTeacherClassSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddTeacherClass() {
  yield takeEvery(TEACHERCLASS_ADD, addTeacherClass);
}


function* updateTeacherClass({ payload }) {
  try {
    const data = yield call(updateTeacherClassRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateTeacherClassSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateTeacherClass() {
  yield takeEvery(TEACHERCLASS_UPDATE, updateTeacherClass);
}

function* deleteTeacherClass({ payload }) {
  try {
    const data = yield call(deleteTeacherClassRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadTeacherClassesAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllTeacherClasses({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteTeacherClassRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadTeacherClassesAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteTeacherClass() {
  yield takeEvery(TEACHERCLASS_DELETE, deleteTeacherClass);
}

function* processDeleteAllTeacherClasses() {
  yield takeEvery(TEACHERCLASS_DELETE_ALL, deleteAllTeacherClasses);
}

export default function* TeacherClassSagas() {
  yield all([fork(processLoadTeacherClasses),
  fork(processLoadTeacherClass),
  fork(processAddTeacherClass),
  fork(processUpdateTeacherClass),
  fork(processDeleteAllTeacherClasses),
  fork(processDeleteTeacherClass)]);
}
