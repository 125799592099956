export const MOMENT_DATE_FORMAT = "DD/MM/YYYY";
export const FULL_MOMENT_DATE_FORMAT = "DD/MM/YYYY HH:mm";
export const DATE_FORMAT = "dd/MM/yyyy";
export const MONTH_FORMAT = "MM/yyyy";
export const DATE_TIME_FORMAT = "dd/MM/yyyy h:mm aa";
export const SQL_DATE_FORMAT = "YYYY/MM/DD";
export const HASH_CODE_FORMAT = "YYYYMMDDHHmmss";
export const FILE_NAME_HASH = 'YYYYMMDDHHmmsszzz';
export const LANGUAGE_CODES = [{ code: 'en', name: 'English' }, { code: 'vi', name: 'Việt Nam' }];
export const DEFAULT_LANGUAGE = "vi";
export const GET_ALL_SIZE = 100000;
export const PAGE_SIZES = 30;
export const PAGING_PADDING = 5;
export const KEY_CODES = {
  COMMA: 188,
  ENTER: 13
}
export const DATA_TYPE = {
  SELECT: 'select',
  CHECKBOX: 'checkbox',
  DATE_TIME: 'datetime',
  TEXT: 'text',
  LABEL: 'label',
  EDITOR: 'editor',
  MAIL: 'mail',
  PHONE: 'phone',
  SUGGESTION: 'suggestion',
  UPLOAD: 'upload',
  UPLOAD_ALBUM: 'upload_album',
  NUMBER: 'number',
  TAGINPUTS: 'taginputs',
  CUSTOM: 'custom'
}
export const ACTION_STATUSES = {
  NORMAL: 0,
  DELETED: 1,
  INSERTED: 2,
  UPDATED: 3
}
export const ACTION_BUTTON_TYPES = {
  SITE_MAP: {
    type: 'SITE_MAP',
    icon: 'fa fa-sitemap',
    color: 'success',
    tooltip: 'dark',
    text: 'Cây tổ chức'
  },
  LIST_VIEW: {
    type: 'LIST_VIEW',
    icon: 'icon-list',
    color: 'success',
    tooltip: 'dark',
    text: 'Xem danh sách'
  },
  VIEW: {
    type: 'VIEW',
    icon: 'icon-eye',
    color: 'default',
    tooltip: 'dark',
    text: 'Xem'
  },
  ADD: {
    type: 'ADD',
    icon: 'icon-plus',
    color: 'success',
    tooltip: 'success',
    text: 'Thêm'
  },
  SAVE: {
    type: 'SAVE',
    icon: 'icon-cloud-download',
    color: 'success',
    tooltip: 'success',
    text: 'Lưu'
  },
  EDIT: {
    type: 'EDIT',
    icon: 'fa fa-edit',
    color: 'primary',
    tooltip: 'info',
    text: 'Sửa'
  },
  TUTIONFEEPAYMENT: {
    type: 'TUTIONFEEPAYMENT',
    icon: 'fa fa-rouble',
    color: 'success',
    tooltip: 'dark',
    text: 'Thanh toán học phí'
  },
  REFRESH: {
    type: 'REFRESH',
    icon: 'icon-refresh',
    color: 'info',
    tooltip: 'info',
    text: 'Làm mới'
  },
  LOCK: {
    type: 'LOCK',
    icon: 'icon-lock',
    color: 'danger',
    tooltip: 'error',
    text: 'Khóa'
  },
  UNLOCK: {
    type: 'UNLOCK',
    icon: 'icon-lock-open',
    color: 'success',
    tooltip: 'success',
    text: 'Mở khóa'
  },
  CHANGEROLE: {
    type: 'CHANGEROLE',
    icon: 'icon-shield',
    color: 'warning',
    tooltip: 'warning',
    text: 'Phân quyền'
  },
  BACK: {
    type: 'BACK',
    icon: 'icon-action-undo',
    color: 'default',
    tooltip: 'dark',
    text: 'Quay lại'
  },
  DELETE: {
    type: 'DELETE',
    icon: 'icon-trash',
    color: 'danger',
    tooltip: 'error',
    text: 'Xóa'
  },
  QUOTATION: {
    type: 'QUOTATION',
    icon: 'icon-plus',
    color: 'success',
    tooltip: 'dark',
    text: 'Thêm báo giá'
  },
  CONTRACT: {
    type: 'CONTRACT',
    icon: 'icon-plus',
    color: 'success',
    tooltip: 'dark',
    text: 'Thêm hợp đồng'
  },
  PAYMENT: {
    type: 'PAYMENT',
    icon: 'icon-plus',
    color: 'success',
    tooltip: 'dark',
    text: 'Thêm nộp phí'
  },
  SEND: {
    type: 'SEND',
    icon: 'fa fa-rocket',
    color: 'success',
    tooltip: 'success',
    text: 'Gửi đi'
  },
  SEARCH: {
    type: 'SEARCH',
    icon: 'fa fa-find',
    color: 'danger',
    tooltip: 'dark',
    text: 'Tìm kiếm'
  },
  CANCEL: {
    type: 'CANCEL',
    icon: 'fa fa-close',
    color: 'default',
    tooltip: 'dark',
    text: 'Hủy'
  }
}
export const ROLES = {
  ADMIN: 'Admin',
  HUMAN_RESOURCE: 'HumanResource',
  STAFF: 'Staff',
  PARENT: 'Parent'
};
export const ROLE_TYPES = {
  ACCESS_ALL: 1,
  ACCESS_SPECIFIC: 2
}
export const ROLE_RIGHTS = [
  {
    role: ROLES.ADMIN,
    type: ROLE_TYPES.ACCESS_ALL
  },
  {
    role: ROLES.HUMAN_RESOURCE,
    type: ROLE_TYPES.ACCESS_SPECIFIC,
    rights: [
      {
        key: 'dashboard'
      },
      {
        key: 'company'
      },
      {
        key: 'department'
      },
      {
        key: 'workLocation'
      },
      {
        key: 'quotation'
      },
      {
        key: 'employee'
      },
      {
        key: 'payment'
      },
      {
        key: 'tutionFee'
      },
      {
        key: 'tutionFeeHistory'
      },
      {
        key: 'student'
      },
      {
        key: 'logout'
      }
    ]
  },
  {
    role: ROLES.STAFF,
    type: ROLE_TYPES.ACCESS_SPECIFIC,
    rights: [
      {
        key: 'logout'
      }
    ]
  },
  {
    role: ROLES.PARENT,
    type: ROLE_TYPES.ACCESS_SPECIFIC,
    rights: [
      {
        key: 'myStudent'
      },
      {
        key: 'tutionFeeHistory',
        restrictActions: [
          ACTION_BUTTON_TYPES.DELETE,
          ACTION_BUTTON_TYPES.EDIT,
          ACTION_BUTTON_TYPES.ADD,
          ACTION_BUTTON_TYPES.TUTIONFEEPAYMENT
        ]
      },
      {
        key: 'quotations'
      },
      {
        key: 'logout'
      }
    ]
  }
]