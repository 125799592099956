import {
  LEAVETRACKHISTORIES_SELECT_ALL,
  LEAVETRACKHISTORIES_UNSELECT_ALL,
  LEAVETRACKHISTORY_SELECTED,
  LEAVETRACKHISTORIES_LOAD,
  LEAVETRACKHISTORIES_LOAD_SUCCESS,
  LEAVETRACKHISTORIES_LOAD_FAILED,
  LEAVETRACKHISTORY_LOAD,
  LEAVETRACKHISTORY_LOAD_SUCCESS,
  LEAVETRACKHISTORY_LOAD_FAILED,
  LEAVETRACKHISTORY_ADD,
  LEAVETRACKHISTORY_ADD_SUCCESS,
  LEAVETRACKHISTORY_ADD_FAILED,
  LEAVETRACKHISTORY_UPDATE,
  LEAVETRACKHISTORY_UPDATE_SUCCESS,
  LEAVETRACKHISTORY_UPDATE_FAILED,
  LEAVETRACKHISTORY_DELETE,
  LEAVETRACKHISTORY_DELETE_SUCCESS,
  LEAVETRACKHISTORY_DELETE_FAILED,
  LEAVETRACKHISTORY_DELETE_ALL,
  LEAVETRACKHISTORY_DELETE_ALL_SUCCESS,
  LEAVETRACKHISTORY_DELETE_ALL_FAILED
} from '../constants/ActionTypes';
import { PAGE_SIZES } from '../constants/AppConstant';

const INIT_STATE = {
  paging: {
    records: [],
    offset: 0,
    limit: PAGE_SIZES,
    totalRecords: 0
  },
  selectedLeaveTrackHistory: null,
  selectedLeaveTrackHistoryId: null,
  message: null,
  messageType: 'info',
  filter: {
    offset: 0,
    limit: PAGE_SIZES,
    orderBy: 'name',
    filterGroups: null
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LEAVETRACKHISTORIES_SELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = true;
            return data;
          })
        }
      }
    case LEAVETRACKHISTORIES_UNSELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = false;
            return data;
          })
        }
      }
    case LEAVETRACKHISTORY_SELECTED:
      return {
        ...state,
        selectedLeaveTrackHistory: action.payload,
        selectedLeaveTrackHistoryId: action.payload.id
      };
    case LEAVETRACKHISTORIES_LOAD:
      return {
        ...state,
        filter: action.payload ? action.payload : state.filter
      };
    case LEAVETRACKHISTORIES_LOAD_SUCCESS:
      return {
        ...state,
        paging: action.payload
      };
    case LEAVETRACKHISTORIES_LOAD_FAILED:
      return {
        ...state
      };
    case LEAVETRACKHISTORY_LOAD:
      return {
        ...state
      };
    case LEAVETRACKHISTORY_LOAD_SUCCESS:
      return {
        ...state
      };
    case LEAVETRACKHISTORY_LOAD_FAILED:
      return {
        ...state
      };
    case LEAVETRACKHISTORY_ADD:
      return {
        ...state
      };
    case LEAVETRACKHISTORY_ADD_SUCCESS:
      return {
        ...state
      };
    case LEAVETRACKHISTORY_ADD_FAILED:
      return {
        ...state
      };
    case LEAVETRACKHISTORY_UPDATE:
      return {
        ...state
      };
    case LEAVETRACKHISTORY_UPDATE_SUCCESS:
      return {
        ...state
      };
    case LEAVETRACKHISTORY_UPDATE_FAILED:
      return {
        ...state
      };
    case LEAVETRACKHISTORY_DELETE:
      return {
        ...state
      };
    case LEAVETRACKHISTORY_DELETE_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case LEAVETRACKHISTORY_DELETE_FAILED:
      return {
        ...state
      };
    case LEAVETRACKHISTORY_DELETE_ALL:
      return {
        ...state
      };
    case LEAVETRACKHISTORY_DELETE_ALL_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case LEAVETRACKHISTORY_DELETE_ALL_FAILED:
      return {
        ...state
      };
    default:
      return state;
  }
}
