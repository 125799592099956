import {
  INVOICES_SELECT_ALL,
  INVOICES_UNSELECT_ALL,
  INVOICE_SELECTED,
  INVOICES_LOAD,
  INVOICES_LOAD_SUCCESS,
  INVOICES_LOAD_FAILED,
  INVOICE_LOAD,
  INVOICE_LOAD_SUCCESS,
  INVOICE_LOAD_FAILED,
  INVOICE_ADD,
  INVOICE_ADD_SUCCESS,
  INVOICE_ADD_FAILED,
  INVOICE_UPDATE,
  INVOICE_UPDATE_SUCCESS,
  INVOICE_UPDATE_FAILED,
  INVOICE_DELETE,
  INVOICE_DELETE_SUCCESS,
  INVOICE_DELETE_FAILED,
  INVOICE_DELETE_ALL,
  INVOICE_DELETE_ALL_SUCCESS,
  INVOICE_DELETE_ALL_FAILED
} from '../constants/ActionTypes';

export const selectAllInvoices = () => {
  return {
    type: INVOICES_SELECT_ALL
  }
}

export const unselectAllInvoices = () => {
  return {
    type: INVOICES_UNSELECT_ALL
  }
}

export const selectInvoice = data => {
  return {
    type: INVOICE_SELECTED,
    payload: data
  }
}

export const loadInvoices = (data) => {
  return {
    type: INVOICES_LOAD,
    payload: data
  };
}

export const loadInvoicesSuccess = (data) => {
  return {
    type: INVOICES_LOAD_SUCCESS,
    payload: data
  };
}

export const loadInvoicesFailed = () => {
  return {
    type: INVOICES_LOAD_FAILED
  };
}

export const loadInvoice = () => {
  return {
    type: INVOICE_LOAD
  };
}

export const loadInvoiceSuccess = (data) => {
  return {
    type: INVOICE_LOAD_SUCCESS,
    payload: data
  };
}


export const loadInvoiceFailed = () => {
  return {
    type: INVOICE_LOAD_FAILED
  };
}


export const addInvoice = (data) => {
  return {
    type: INVOICE_ADD,
    payload: data
  };
}


export const addInvoiceSuccess = (filter) => {
  return {
    type: INVOICE_ADD_SUCCESS,
    message: 'Invoice added successfully.',
    payload: filter
  };
}

export const addInvoiceFailed = () => {
  return {
    type: INVOICE_ADD_FAILED
  };
}

export const updateInvoice = (data) => {
  return {
    type: INVOICE_UPDATE,
    payload: data
  };
}


export const updateInvoiceSuccess = (data) => {
  return {
    type: INVOICE_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateInvoiceFailed = (data) => {
  return {
    type: INVOICE_UPDATE_FAILED,
    payload: data
  };
}

export const deleteInvoice = (data) => {
  return {
    type: INVOICE_DELETE,
    payload: data
  };
}

export const deleteInvoiceSuccess = () => {
  return {
    type: INVOICE_DELETE_SUCCESS,
    message: 'Invoice deleted successfully.'
  };
}

export const deleteInvoiceFailed = () => {
  return {
    type: INVOICE_DELETE_FAILED
  };
}

export const deleteAllInvoices = (data) => {
  return {
    type: INVOICE_DELETE_ALL,
    payload: data
  };
}

export const deleteAllInvoicesSuccess = () => {
  return {
    type: INVOICE_DELETE_ALL_SUCCESS,
    message: 'Invoice all deleted successfully.'
  };
}

export const deleteAllInvoicesFailed = () => {
  return {
    type: INVOICE_DELETE_ALL_FAILED
  };
}
