import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadRoles as loadRolesAction,
  loadRolesSuccess,
  addRoleSuccess,
  updateRoleSuccess
} from '../actions/Role';

import {
  ROLES_LOAD,
  ROLE_ADD,
  ROLE_UPDATE,
  ROLE_DELETE,
  ROLE_DELETE_ALL,
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getRoleById = (id, callback) => {
  Api().get('role/' + id).then(response => {
    callback(response.data);
  })
}

export const getAllRoles = (callback) => {
  Api().post('role/search', { limit: GET_ALL_SIZE })
    .then(response => {
      callback(response.data.records);        
    })
}

const loadRolesRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`role/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`role`, { params })
    .then(data => data)
    .catch(error => error);
}


const addRoleRequest = async (payload) =>
  await Api().post(`role`, payload)
    .then(data => data)
    .catch(error => error);

const updateRoleRequest = async (payload) =>
  await Api().patch(`role/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteRoleRequest = async (id) =>
  await Api().delete(`role/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadRoles({ payload }) {
  try {
    const data = yield call(loadRolesRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadRolesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadRoles() {
  yield takeEvery(ROLES_LOAD, loadRoles);
}

function* loadRole({ selectedRoleId }) {
  try {
    const data = yield call(loadRolesRequest, selectedRoleId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadRolesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadRole() {
  yield takeEvery(ROLES_LOAD, loadRole);
}


function* addRole({ payload }) {
  try {
    const data = yield call(addRoleRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addRoleSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddRole() {
  yield takeEvery(ROLE_ADD, addRole);
}


function* updateRole({ payload }) {
  try {
    const data = yield call(updateRoleRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateRoleSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateRole() {
  yield takeEvery(ROLE_UPDATE, updateRole);
}

function* deleteRole({ payload }) {
  try {
    const data = yield call(deleteRoleRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadRolesAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllRoles({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteRoleRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadRolesAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteRole() {
  yield takeEvery(ROLE_DELETE, deleteRole);
}

function* processDeleteAllRoles() {
  yield takeEvery(ROLE_DELETE_ALL, deleteAllRoles);
}

export default function* RoleSagas() {
  yield all([fork(processLoadRoles),
  fork(processLoadRole),
  fork(processAddRole),
  fork(processUpdateRole),
  fork(processDeleteAllRoles),
  fork(processDeleteRole)]);
}
