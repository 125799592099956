import {
    SHOW_LOADING,
    HIDE_LOADING,
    SAVE_EMPLOYEE_SUCCESS,
    SAVE_USER_SUCCESS,
    SAVE_SELECTED_STUDENT
} from '../constants/ActionTypes';

const INIT_STATE = {
    hideGlobalLoading: true,
    user: localStorage._user___ ? JSON.parse(localStorage._user___) : {},
    employee: localStorage._employee___ ? JSON.parse(localStorage._employee___) : {},
    selectedStudent: null
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case HIDE_LOADING:
            return {
                ...state,
                hideGlobalLoading: true
            }
        case SHOW_LOADING:
            return {
                ...state,
                hideGlobalLoading: false
            }
        case SAVE_SELECTED_STUDENT: 
            return {
                ...state,
                selectedStudent: action.payload
            }
        case SAVE_USER_SUCCESS:
            localStorage._user___ = JSON.stringify(action.payload);
            return {
                ...state,
                user: action.payload,
                selectedStudent: action.payload.students[0]
            }
        case SAVE_EMPLOYEE_SUCCESS:
            localStorage._employee___ = JSON.stringify(action.payload);
            return {
                ...state,
                employee: action.payload
            }
        default:
            return state;
    }
}
