import {
  CLASSROOMS_SELECT_ALL,
  CLASSROOMS_UNSELECT_ALL,
  CLASSROOM_SELECTED,
  CLASSROOMS_LOAD,
  CLASSROOMS_LOAD_SUCCESS,
  CLASSROOMS_LOAD_FAILED,
  CLASSROOM_LOAD,
  CLASSROOM_LOAD_SUCCESS,
  CLASSROOM_LOAD_FAILED,
  CLASSROOM_ADD,
  CLASSROOM_ADD_SUCCESS,
  CLASSROOM_ADD_FAILED,
  CLASSROOM_UPDATE,
  CLASSROOM_UPDATE_SUCCESS,
  CLASSROOM_UPDATE_FAILED,
  CLASSROOM_DELETE,
  CLASSROOM_DELETE_SUCCESS,
  CLASSROOM_DELETE_FAILED,
  CLASSROOM_DELETE_ALL,
  CLASSROOM_DELETE_ALL_SUCCESS,
  CLASSROOM_DELETE_ALL_FAILED
} from '../constants/ActionTypes';

export const selectAllClassRooms = () => {
  return {
    type: CLASSROOMS_SELECT_ALL
  }
}

export const unselectAllClassRooms = () => {
  return {
    type: CLASSROOMS_UNSELECT_ALL
  }
}

export const selectClassRoom = data => {
  return {
    type: CLASSROOM_SELECTED,
    payload: data
  }
}

export const loadClassRooms = (data) => {
  return {
    type: CLASSROOMS_LOAD,
    payload: data
  };
}

export const loadClassRoomsSuccess = (data) => {
  return {
    type: CLASSROOMS_LOAD_SUCCESS,
    payload: data
  };
}

export const loadClassRoomsFailed = () => {
  return {
    type: CLASSROOMS_LOAD_FAILED
  };
}

export const loadClassRoom = () => {
  return {
    type: CLASSROOM_LOAD
  };
}

export const loadClassRoomSuccess = (data) => {
  return {
    type: CLASSROOM_LOAD_SUCCESS,
    payload: data
  };
}


export const loadClassRoomFailed = () => {
  return {
    type: CLASSROOM_LOAD_FAILED
  };
}


export const addClassRoom = (data) => {
  return {
    type: CLASSROOM_ADD,
    payload: data
  };
}


export const addClassRoomSuccess = (filter) => {
  return {
    type: CLASSROOM_ADD_SUCCESS,
    message: 'ClassRoom added successfully.',
    payload: filter
  };
}

export const addClassRoomFailed = () => {
  return {
    type: CLASSROOM_ADD_FAILED
  };
}

export const updateClassRoom = (data) => {
  return {
    type: CLASSROOM_UPDATE,
    payload: data
  };
}


export const updateClassRoomSuccess = (data) => {
  return {
    type: CLASSROOM_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateClassRoomFailed = (data) => {
  return {
    type: CLASSROOM_UPDATE_FAILED,
    payload: data
  };
}

export const deleteClassRoom = (data) => {
  return {
    type: CLASSROOM_DELETE,
    payload: data
  };
}

export const deleteClassRoomSuccess = () => {
  return {
    type: CLASSROOM_DELETE_SUCCESS,
    message: 'ClassRoom deleted successfully.'
  };
}

export const deleteClassRoomFailed = () => {
  return {
    type: CLASSROOM_DELETE_FAILED
  };
}

export const deleteAllClassRooms = (data) => {
  return {
    type: CLASSROOM_DELETE_ALL,
    payload: data
  };
}

export const deleteAllClassRoomsSuccess = () => {
  return {
    type: CLASSROOM_DELETE_ALL_SUCCESS,
    message: 'ClassRoom all deleted successfully.'
  };
}

export const deleteAllClassRoomsFailed = () => {
  return {
    type: CLASSROOM_DELETE_ALL_FAILED
  };
}
