import axios from 'axios';
import {
  ApiUrl
} from './settings';
axios.defaults.headers.post['Content-Type'] = 'application/json';

export default function Api(config) {
  if (config) {
    if (config.headers) {
      return axios.create({
        ...config,
        baseURL: ApiUrl + '/',
        timeout: 30000,
        headers: {
          ...config.headers,
          'Authorization': `Bearer ${localStorage._ra___}`
        }
      });
    } else {
      return axios.create({
        ...config,
        baseURL: ApiUrl + '/',
        timeout: 30000,
        headers: {
          'Authorization': `Bearer ${localStorage._ra___}`
        }
      });
    }
  } else {
    return axios.create({
      baseURL: ApiUrl + '/',
      timeout: 30000,
      headers: {
        'Authorization': `Bearer ${localStorage._ra___}`
      }
    });
  }
};

export function patchData(data) {
  var result = [];
  Object.keys(data).map(prop => {
    if (prop === 'id' || prop === 'selected') return;
    result.push({
      op: 'replace',
      path: '/' + prop,
      value: data[prop],
    });

    return prop;
  });

  return result;
}
