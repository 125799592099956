import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadInvoices as loadInvoicesAction,
  loadInvoicesSuccess,
  addInvoiceSuccess,
  updateInvoiceSuccess
} from '../actions/Invoice';

import {
  INVOICES_LOAD,
  INVOICE_ADD,
  INVOICE_UPDATE,
  INVOICE_DELETE,
  INVOICE_DELETE_ALL,
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getInvoiceById = (id, callback) => {
  Api().get('invoice/' + id).then(response => {
    callback(response.data);
  })
}

export const getAllInvoices = (callback) => {
  Api().post('invoice/search', { limit: GET_ALL_SIZE })
    .then(response => {
      callback(response.data.records);        
    })
}

const loadInvoicesRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`invoice/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`invoice`, { params })
    .then(data => data)
    .catch(error => error);
}


const addInvoiceRequest = async (payload) =>
  await Api().post(`invoice`, payload)
    .then(data => data)
    .catch(error => error);

const updateInvoiceRequest = async (payload) =>
  await Api().patch(`invoice/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteInvoiceRequest = async (id) =>
  await Api().delete(`invoice/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadInvoices({ payload }) {
  try {
    const data = yield call(loadInvoicesRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadInvoicesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadInvoices() {
  yield takeEvery(INVOICES_LOAD, loadInvoices);
}

function* loadInvoice({ selectedInvoiceId }) {
  try {
    const data = yield call(loadInvoicesRequest, selectedInvoiceId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadInvoicesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadInvoice() {
  yield takeEvery(INVOICES_LOAD, loadInvoice);
}


function* addInvoice({ payload }) {
  try {
    const data = yield call(addInvoiceRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addInvoiceSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddInvoice() {
  yield takeEvery(INVOICE_ADD, addInvoice);
}


function* updateInvoice({ payload }) {
  try {
    const data = yield call(updateInvoiceRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateInvoiceSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateInvoice() {
  yield takeEvery(INVOICE_UPDATE, updateInvoice);
}

function* deleteInvoice({ payload }) {
  try {
    const data = yield call(deleteInvoiceRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadInvoicesAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllInvoices({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteInvoiceRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadInvoicesAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteInvoice() {
  yield takeEvery(INVOICE_DELETE, deleteInvoice);
}

function* processDeleteAllInvoices() {
  yield takeEvery(INVOICE_DELETE_ALL, deleteAllInvoices);
}

export default function* InvoiceSagas() {
  yield all([fork(processLoadInvoices),
  fork(processLoadInvoice),
  fork(processAddInvoice),
  fork(processUpdateInvoice),
  fork(processDeleteAllInvoices),
  fork(processDeleteInvoice)]);
}
