import {
  TUTIONFEES_SELECT_ALL,
  TUTIONFEES_UNSELECT_ALL,
  TUTIONFEE_SELECTED,
  TUTIONFEES_LOAD,
  TUTIONFEES_LOAD_SUCCESS,
  TUTIONFEES_LOAD_FAILED,
  TUTIONFEE_LOAD,
  TUTIONFEE_LOAD_SUCCESS,
  TUTIONFEE_LOAD_FAILED,
  TUTIONFEE_ADD,
  TUTIONFEE_ADD_SUCCESS,
  TUTIONFEE_ADD_FAILED,
  TUTIONFEE_UPDATE,
  TUTIONFEE_UPDATE_SUCCESS,
  TUTIONFEE_UPDATE_FAILED,
  TUTIONFEE_DELETE,
  TUTIONFEE_DELETE_SUCCESS,
  TUTIONFEE_DELETE_FAILED,
  TUTIONFEE_DELETE_ALL,
  TUTIONFEE_DELETE_ALL_SUCCESS,
  TUTIONFEE_DELETE_ALL_FAILED
} from '../constants/ActionTypes';

export const selectAllTutionFees = () => {
  return {
    type: TUTIONFEES_SELECT_ALL
  }
}

export const unselectAllTutionFees = () => {
  return {
    type: TUTIONFEES_UNSELECT_ALL
  }
}

export const selectTutionFee = data => {
  return {
    type: TUTIONFEE_SELECTED,
    payload: data
  }
}

export const loadTutionFees = (data) => {
  return {
    type: TUTIONFEES_LOAD,
    payload: data
  };
}

export const loadTutionFeesSuccess = (data) => {
  return {
    type: TUTIONFEES_LOAD_SUCCESS,
    payload: data
  };
}

export const loadTutionFeesFailed = () => {
  return {
    type: TUTIONFEES_LOAD_FAILED
  };
}

export const loadTutionFee = () => {
  return {
    type: TUTIONFEE_LOAD
  };
}

export const loadTutionFeeSuccess = (data) => {
  return {
    type: TUTIONFEE_LOAD_SUCCESS,
    payload: data
  };
}


export const loadTutionFeeFailed = () => {
  return {
    type: TUTIONFEE_LOAD_FAILED
  };
}


export const addTutionFee = (data) => {
  return {
    type: TUTIONFEE_ADD,
    payload: data
  };
}


export const addTutionFeeSuccess = (filter) => {
  return {
    type: TUTIONFEE_ADD_SUCCESS,
    message: 'TutionFee added successfully.',
    payload: filter
  };
}

export const addTutionFeeFailed = () => {
  return {
    type: TUTIONFEE_ADD_FAILED
  };
}

export const updateTutionFee = (data) => {
  return {
    type: TUTIONFEE_UPDATE,
    payload: data
  };
}


export const updateTutionFeeSuccess = (data) => {
  return {
    type: TUTIONFEE_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateTutionFeeFailed = (data) => {
  return {
    type: TUTIONFEE_UPDATE_FAILED,
    payload: data
  };
}

export const deleteTutionFee = (data) => {
  return {
    type: TUTIONFEE_DELETE,
    payload: data
  };
}

export const deleteTutionFeeSuccess = () => {
  return {
    type: TUTIONFEE_DELETE_SUCCESS,
    message: 'TutionFee deleted successfully.'
  };
}

export const deleteTutionFeeFailed = () => {
  return {
    type: TUTIONFEE_DELETE_FAILED
  };
}

export const deleteAllTutionFees = (data) => {
  return {
    type: TUTIONFEE_DELETE_ALL,
    payload: data
  };
}

export const deleteAllTutionFeesSuccess = () => {
  return {
    type: TUTIONFEE_DELETE_ALL_SUCCESS,
    message: 'TutionFee all deleted successfully.'
  };
}

export const deleteAllTutionFeesFailed = () => {
  return {
    type: TUTIONFEE_DELETE_ALL_FAILED
  };
}
