import {
  STUDENTS_SELECT_ALL,
  STUDENTS_UNSELECT_ALL,
  STUDENT_SELECTED,
  STUDENTS_LOAD,
  STUDENTS_LOAD_SUCCESS,
  STUDENTS_LOAD_FAILED,
  MY_STUDENTS_SELECT_ALL,
  MY_STUDENTS_UNSELECT_ALL,
  MY_STUDENTS_LOAD,
  MY_STUDENTS_LOAD_SUCCESS,
  MY_STUDENTS_LOAD_FAILED,
  STUDENT_LOAD,
  STUDENT_LOAD_SUCCESS,
  STUDENT_LOAD_FAILED,
  STUDENT_ADD,
  STUDENT_ADD_SUCCESS,
  STUDENT_ADD_FAILED,
  STUDENT_UPDATE,
  STUDENT_UPDATE_SUCCESS,
  STUDENT_UPDATE_FAILED,
  STUDENT_DELETE,
  STUDENT_DELETE_SUCCESS,
  STUDENT_DELETE_FAILED,
  STUDENT_DELETE_ALL,
  STUDENT_DELETE_ALL_SUCCESS,
  STUDENT_DELETE_ALL_FAILED,
  STUDENTS_EXPORT_EXCEL,
  STUDENTS_EXPORT_EXCEL_SUCCESS,
  STUDENTS_EXPORT_EXCEL_FAILED
} from '../constants/ActionTypes';
import { PAGE_SIZES } from '../constants/AppConstant';

const INIT_STATE = {
  paging: {
    records: [],
    offset: 0,
    limit: PAGE_SIZES,
    totalRecords: 0
  },
  mcPaging: {
    records: [],
    offset: 0,
    limit: PAGE_SIZES,
    totalRecords: 0
  },
  selectedStudent: null,
  selectedStudentId: null,
  message: null,
  messageType: 'info',
  filter: {
    offset: 0,
    limit: PAGE_SIZES,
    orderBy: 'id',
    descending: true,
    filterGroups: [{
      filters: [{
        property: 'status',
        operator: 'equal',
        value: 1
      }]
    }]
  },
  mcFilter: {
    offset: 0,
    limit: PAGE_SIZES,
    orderBy: 'id',
    filterGroups: null
  },
  hideLoading: true
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case STUDENTS_SELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = true;
            return data;
          })
        }
      }
    case STUDENTS_UNSELECT_ALL:
      return {
        ...state,
        paging: {
          ...state.paging,
          records: state.paging.records.map(data => {
            data.selected = false;
            return data;
          })
        }
      }
      case MY_STUDENTS_SELECT_ALL:
        return {
          ...state,
          mcPaging: {
            ...state.mcPaging,
            records: state.mcPaging.records.map(data => {
              data.selected = true;
              return data;
            })
          }
        }
      case MY_STUDENTS_UNSELECT_ALL:
        return {
          ...state,
          mcPaging: {
            ...state.mcPaging,
            records: state.mcPaging.records.map(data => {
              data.selected = false;
              return data;
            })
          }
        }
    case STUDENT_SELECTED:
      return {
        ...state,
        selectedStudent: action.payload,
        selectedStudentId: action.payload.id
      };
    case STUDENTS_LOAD:
      return {
        ...state,
        filter: action.payload ? action.payload : state.filter,
        hideLoading: false
      };
    case STUDENTS_LOAD_SUCCESS:
      return {
        ...state,
        paging: action.payload,
        hideLoading: true
      };
    case STUDENTS_LOAD_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case MY_STUDENTS_LOAD:
      return {
        ...state,
        mcFilter: action.payload ? action.payload : state.mcFilter,
        hideLoading: false
      };
    case MY_STUDENTS_LOAD_SUCCESS:
      return {
        ...state,
        mcPaging: action.payload,
        hideLoading: true
      };
    case MY_STUDENTS_LOAD_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case STUDENT_LOAD:
      return {
        ...state,
        hideLoading: false
      };
    case STUDENT_LOAD_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case STUDENT_LOAD_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case STUDENT_ADD:
      return {
        ...state,
        hideLoading: false
      };
    case STUDENT_ADD_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case STUDENT_ADD_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case STUDENT_UPDATE:
      return {
        ...state,
        hideLoading: false
      };
    case STUDENT_UPDATE_SUCCESS:
      return {
        ...state,
        hideLoading: true
      };
    case STUDENT_UPDATE_FAILED:
      return {
        ...state,
        hideLoading: true
      };
    case STUDENT_DELETE:
      return {
        ...state
      };
    case STUDENT_DELETE_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case STUDENT_DELETE_FAILED:
      return {
        ...state
      };
    case STUDENT_DELETE_ALL:
      return {
        ...state
      };
    case STUDENT_DELETE_ALL_SUCCESS:
      return {
        ...state,
        message: action.message
      };
    case STUDENT_DELETE_ALL_FAILED:
      return {
        ...state
      };
      case STUDENTS_EXPORT_EXCEL:
        return {
          ...state,
          excelBlob: null
        };
      case STUDENTS_EXPORT_EXCEL_SUCCESS:
        return {
          ...state,
          excelBlob: action.payload
        };
      case STUDENTS_EXPORT_EXCEL_FAILED:
        return {
          ...state,
          excelBlob: null
        };
    default:
      return state;
  }
}
