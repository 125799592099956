import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE, PAGE_SIZES } from '../constants/AppConstant';

import {
  loadStudents as loadStudentsAction,
  loadStudentsSuccess,
  addStudentSuccess,
  updateStudentSuccess,
  loadMyStudents as loadMyStudentsAction,
  loadMyStudentsSuccess,
  exportExcelStudents,
  exportExcelStudentsSuccess,
  exportExcelStudentsFailed
} from '../actions/Student';

import {
  STUDENTS_LOAD,
  MY_STUDENTS_LOAD,
  STUDENT_ADD,
  STUDENT_UPDATE,
  STUDENT_DELETE,
  STUDENT_DELETE_ALL,
  STUDENTS_EXPORT_EXCEL,
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getStudentById = (id, callback) => {
  Api().get('student/' + id).then(response => {
    callback(response.data);
  })
}

export const getAllStudents = (callback) => {
  Api().post('student/search', { limit: GET_ALL_SIZE })
    .then(response => {
      callback(response.data.records);
    })
}

export const getAllStudentsByStatus = (callback) => {
  Api().post('student/search', {
    limit: GET_ALL_SIZE,
    filterGroups: [{
      filters: [{
        property: 'status',
        operator: '!=',
        value: 2
      }]
    }]
  }).then(response => {
    callback(response.data.records);
  })
}

export const getStudentsByCompany = (type, company, callback) => {
  Api().post('student/search', {
    limit: GET_ALL_SIZE,
    filterGroups: [{
      filters: [{
        property: 'companyId',
        operator: 'equal',
        value: type === 'firstLoad' ? company.id : company.companyId
      }]
    }]
  }).then(response => {
    callback(response.data.records);
  })
}

const loadStudentsRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`student/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`student`, { params })
    .then(data => data)
    .catch(error => error);
}

const addStudentRequest = async (payload) =>
  await Api().post(`student`, payload)
    .then(data => data)
    .catch(error => error);

const updateStudentRequest = async (payload) =>
  await Api().patch(`student/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteStudentRequest = async (id) =>
  await Api().delete(`student/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadStudents({ payload }) {
  try {
    const data = yield call(loadStudentsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadStudentsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadStudents() {
  yield takeEvery(STUDENTS_LOAD, loadStudents);
}

function* loadMyStudents({ payload }) {
  try {
    const data = yield call(loadStudentsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadMyStudentsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadMyStudents() {
  yield takeEvery(MY_STUDENTS_LOAD, loadMyStudents);
}

function* loadStudent({ selectedStudentId }) {
  try {
    const data = yield call(loadStudentsRequest, selectedStudentId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadStudentsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadStudent() {
  yield takeEvery(STUDENTS_LOAD, loadStudent);
}


function* addStudent({ payload }) {
  try {
    const data = yield call(addStudentRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addStudentSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddStudent() {
  yield takeEvery(STUDENT_ADD, addStudent);
}


function* updateStudent({ payload }) {
  try {
    const data = yield call(updateStudentRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateStudentSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateStudent() {
  yield takeEvery(STUDENT_UPDATE, updateStudent);
}

function* deleteStudent({ payload }) {
  try {
    const data = yield call(deleteStudentRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadStudentsAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllStudents({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteStudentRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadStudentsAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteStudent() {
  yield takeEvery(STUDENT_DELETE, deleteStudent);
}

function* processDeleteAllStudents() {
  yield takeEvery(STUDENT_DELETE_ALL, deleteAllStudents);
}


const exportStudentsRequest = async (params) => {
  return await Api({responseType: 'blob'}).post(`student/export/excel`, params)
    .then(data => data)
    .catch(error => error);
}

function* exportStudents({ payload }) {
  try {
    const data = yield call(exportStudentsRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(exportExcelStudentsSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processExportStudents() {
  yield takeEvery(STUDENTS_EXPORT_EXCEL, exportStudents);
}

export default function* StudentSagas() {
  yield all([fork(processLoadStudents),
  fork(processLoadMyStudents),
  fork(processLoadStudent),
  fork(processAddStudent),
  fork(processUpdateStudent),
  fork(processDeleteAllStudents),
  fork(processDeleteStudent), 
  fork(processExportStudents)]);
}
