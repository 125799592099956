import {
  QUOTATIONS_SELECT_ALL,
  QUOTATIONS_UNSELECT_ALL,
  QUOTATION_SELECTED,
  QUOTATIONS_LOAD,
  QUOTATIONS_LOAD_SUCCESS,
  QUOTATIONS_LOAD_FAILED,
  QUOTATION_LOAD,
  QUOTATION_LOAD_SUCCESS,
  QUOTATION_LOAD_FAILED,
  QUOTATION_ADD,
  QUOTATION_ADD_SUCCESS,
  QUOTATION_ADD_FAILED,
  QUOTATION_UPDATE,
  QUOTATION_UPDATE_SUCCESS,
  QUOTATION_UPDATE_FAILED,
  QUOTATION_DELETE,
  QUOTATION_DELETE_SUCCESS,
  QUOTATION_DELETE_FAILED,
  QUOTATION_DELETE_ALL,
  QUOTATION_DELETE_ALL_SUCCESS,
  QUOTATION_DELETE_ALL_FAILED
} from '../constants/ActionTypes';

export const selectAllQuotations = () => {
  return {
    type: QUOTATIONS_SELECT_ALL
  }
}

export const unselectAllQuotations = () => {
  return {
    type: QUOTATIONS_UNSELECT_ALL
  }
}

export const selectQuotation = data => {
  return {
    type: QUOTATION_SELECTED,
    payload: data
  }
}

export const loadQuotations = (data) => {
  return {
    type: QUOTATIONS_LOAD,
    payload: data
  };
}

export const loadQuotationsSuccess = (data) => {
  return {
    type: QUOTATIONS_LOAD_SUCCESS,
    payload: data
  };
}

export const loadQuotationsFailed = () => {
  return {
    type: QUOTATIONS_LOAD_FAILED
  };
}

export const loadQuotation = () => {
  return {
    type: QUOTATION_LOAD
  };
}

export const loadQuotationSuccess = (data) => {
  return {
    type: QUOTATION_LOAD_SUCCESS,
    payload: data
  };
}


export const loadQuotationFailed = () => {
  return {
    type: QUOTATION_LOAD_FAILED
  };
}


export const addQuotation = (data) => {
  return {
    type: QUOTATION_ADD,
    payload: data
  };
}


export const addQuotationSuccess = (filter) => {
  return {
    type: QUOTATION_ADD_SUCCESS,
    message: 'Quotation added successfully.',
    payload: filter
  };
}

export const addQuotationFailed = () => {
  return {
    type: QUOTATION_ADD_FAILED
  };
}

export const updateQuotation = (data) => {
  return {
    type: QUOTATION_UPDATE,
    payload: data
  };
}


export const updateQuotationSuccess = (data) => {
  return {
    type: QUOTATION_UPDATE_SUCCESS,
    payload: data
  };
}

export const updateQuotationFailed = (data) => {
  return {
    type: QUOTATION_UPDATE_FAILED,
    payload: data
  };
}

export const deleteQuotation = (data) => {
  return {
    type: QUOTATION_DELETE,
    payload: data
  };
}

export const deleteQuotationSuccess = () => {
  return {
    type: QUOTATION_DELETE_SUCCESS,
    message: 'Quotation deleted successfully.'
  };
}

export const deleteQuotationFailed = () => {
  return {
    type: QUOTATION_DELETE_FAILED
  };
}

export const deleteAllQuotations = (data) => {
  return {
    type: QUOTATION_DELETE_ALL,
    payload: data
  };
}

export const deleteAllQuotationsSuccess = () => {
  return {
    type: QUOTATION_DELETE_ALL_SUCCESS,
    message: 'Quotation all deleted successfully.'
  };
}

export const deleteAllQuotationsFailed = () => {
  return {
    type: QUOTATION_DELETE_ALL_FAILED
  };
}
