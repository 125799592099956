import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import Api, { patchData } from './Api';
import { GET_ALL_SIZE } from '../constants/AppConstant';

import {
  loadTutionFees as loadTutionFeesAction,
  loadTutionFeesSuccess,
  addTutionFeeSuccess,
  updateTutionFeeSuccess
} from '../actions/TutionFee';

import {
  TUTIONFEES_LOAD,
  TUTIONFEE_ADD,
  TUTIONFEE_UPDATE,
  TUTIONFEE_DELETE,
  TUTIONFEE_DELETE_ALL,
} from '../constants/ActionTypes';

import {
  userSignOut
} from '../actions/Auth';

export const getTutionFeeById = (id, callback) => {
  Api().get('tution-fee/' + id).then(response => {
    callback(response.data);
  })
}

export const getAllTutionFees = (callback) => {
  Api().post('tution-fee/search', { limit: GET_ALL_SIZE })
    .then(response => {
      callback(response.data.records);        
    })
}

const loadTutionFeesRequest = async (params) => {
  if (params.filterGroups && params.filterGroups.length) {
    return await Api().post(`tution-fee/search`, params)
      .then(data => data)
      .catch(error => error);
  }

  return await Api().get(`tution-fee`, { params })
    .then(data => data)
    .catch(error => error);
}


const addTutionFeeRequest = async (payload) =>
  await Api().post(`tution-fee`, payload)
    .then(data => data)
    .catch(error => error);

const updateTutionFeeRequest = async (payload) =>
  await Api().patch(`tution-fee/${payload.id}`, patchData(payload))
    .then(data => data)
    .catch(error => error);

export const deleteTutionFeeRequest = async (id) =>
  await Api().delete(`tution-fee/${id}`)
    .then(data => data)
    .catch(error => error);

function* loadTutionFees({ payload }) {
  try {
    const data = yield call(loadTutionFeesRequest, payload);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadTutionFeesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadTutionFees() {
  yield takeEvery(TUTIONFEES_LOAD, loadTutionFees);
}

function* loadTutionFee({ selectedTutionFeeId }) {
  try {
    const data = yield call(loadTutionFeesRequest, selectedTutionFeeId);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadTutionFeesSuccess(data.data));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processLoadTutionFee() {
  yield takeEvery(TUTIONFEES_LOAD, loadTutionFee);
}


function* addTutionFee({ payload }) {
  try {
    const data = yield call(addTutionFeeRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(addTutionFeeSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processAddTutionFee() {
  yield takeEvery(TUTIONFEE_ADD, addTutionFee);
}


function* updateTutionFee({ payload }) {
  try {
    const data = yield call(updateTutionFeeRequest, payload.model);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(updateTutionFeeSuccess(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processUpdateTutionFee() {
  yield takeEvery(TUTIONFEE_UPDATE, updateTutionFee);
}

function* deleteTutionFee({ payload }) {
  try {
    const data = yield call(deleteTutionFeeRequest, payload.model.id);
    data.status == 401 || data.response && data.response.status == 401 ? yield put(userSignOut()) :
    yield put(loadTutionFeesAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* deleteAllTutionFees({ payload }) {
  try {
    for (let i = 0; i < payload.models.length; ++i) {
      const data = yield call(deleteTutionFeeRequest, payload.models[i].id);
      if (data.status == 401 || data.response && data.response.status == 401) { yield put(userSignOut()); break; }
    }

    yield put(loadTutionFeesAction(payload.filter));
  } catch (error) {
    //yield put(showAuthMessage(error));
  }
}

function* processDeleteTutionFee() {
  yield takeEvery(TUTIONFEE_DELETE, deleteTutionFee);
}

function* processDeleteAllTutionFees() {
  yield takeEvery(TUTIONFEE_DELETE_ALL, deleteAllTutionFees);
}

export default function* TutionFeeSagas() {
  yield all([fork(processLoadTutionFees),
  fork(processLoadTutionFee),
  fork(processAddTutionFee),
  fork(processUpdateTutionFee),
  fork(processDeleteAllTutionFees),
  fork(processDeleteTutionFee)]);
}
